import Cookies from 'js-cookie'
import { COOKIE_DOMAIN } from './index'

const config = {
  domain: COOKIE_DOMAIN
}

export default {
  get (key) {
    return Cookies.get(key, config)
  },
  set (key, value) {
    Cookies.set(key, value, Object.assign({ expires: 1 }, config))
  },
  remove (key) {
    Cookies.remove(key, config)
  }
}
