<template>
  <div class="section_title">
    <span></span>
    <template v-if="multiple">
      <slot></slot>
    </template>
    <template v-else>
      <p>{{ title }}</p>
    </template>
  </div>
</template>
<script>
export default {
  props: {
    multiple: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    }
  }
}
</script>
<style lang="scss" scoped>
  .section_title {
    display: flex;
    align-items: center;
    height: 30px;
    & > span {
      width:2px;
      height:15px;
      background:$color-primary;
      border-radius:2px;
      margin-right: 10px;
    }
    & > p {
      font-size:16px;
      line-height: 30px;
      color: $color-primary;
    }
  }
</style>
