<!-- 团队管理员可以进行
      1、系统设置
      2、人员管理
-->
<template>
  <div class="avatar">
    <el-popover
      trigger="click"
      :open-delay="0"
      placement="bottom"
      :visible-arrow="false"
      popper-class="my_popover">
      <div class="my_popover_list">
        <div class="my_popover_list_item" @click="showChangeInfo = true">
          <i class="iconfont icongeren"></i>
          <p class="text_size_14 text_color_white">个人信息</p>
        </div>
        <div class="my_popover_list_item" @click="showChangePassword = true">
          <i class="iconfont iconmima"></i>
          <p class="text_size_14 text_color_white">修改密码</p>
        </div>
        <div class="my_popover_list_item" @click="onLogout">
          <i class="iconfont icontuichu"></i>
          <p class="text_size_14 text_color_white">注销登录</p>
        </div>
      </div>
      <i slot="reference" class="iconfont icongeren1"></i>
    </el-popover>

    <!-- 修改密码 -->
    <reset-pw
      :visible.sync="showChangePassword">
    </reset-pw>

    <!-- 修改用户信息 -->
    <user-info
      :visible.sync="showChangeInfo">
    </user-info>
  </div>
</template>

<script>
// import * as api from '~/api'
import cookie from '~/config/cookie'
import resetPw from './reset-pw'
import userInfo from './user-info'
export default {
  data () {
    return {
      // 更新个人信息的弹窗
      showChangeInfo: false,
      // 修改密码的弹窗
      showChangePassword: false
    }
  },
  computed: {
  },
  components: {
    resetPw,
    userInfo
  },
  mounted () {
    this.$store.dispatch('getUserInfo')
  },
  methods: {
    // 点击注销登录
    onLogout () {
      cookie.remove('token')
      this.$router.push('/login')
    }
  }
}
</script>

<style lang="scss" scoped>
  .avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-right: 27px;
    cursor: pointer;
    &:hover {
      background-color: $black-color-light-3;
    }
    .icongeren1 {
      font-size: 26px;
      font-size: 26px;
      color: $black-color-light-10;
    }
  }
</style>
