import Vue from 'vue'
import Router from 'vue-router'
import ProgressBar from '~/components/progress-bar.vue'
import cookie from '~/config/cookie'

// 全局进度条
const bar = Vue.prototype.$bar = new Vue(ProgressBar).$mount()
document.body.appendChild(bar.$el)

Vue.use(Router)

const routes = [
  // 重定向首页
  {
    path: '/',
    redirect: '/project-manage',
    component: () => import('../views/index'),
    children: [
      // 项目列表和空间列表
      {
        path: 'project-manage',
        component: () => import('../views/index/project-manage'),
        meta: {
          requireAuth: true,
          sidebar: 'project'
        }
      },
      // 点击某个项目或空间进入到此项目或空间的详情
      {
        path: 'project-detail',
        component: () => import('../views/index/project-detail'),
        redirect: 'project-detail/chart-group',
        meta: {
          requireAuth: true,
          sidebar: 'project'
        },
        children: [
          {
            path: 'chart-group',
            component: () => import('../views/index/project-detail/chart-manage/chart-group/index.vue'),
            meta: {
              sidebar: 'project'
            }
          },
          {
            path: 'type-group',
            component: () => import('../views/index/project-detail/chart-manage/type-group/index.vue'),
            meta: {
              sidebar: 'project'
            }
          },
          {
            path: 'chart-list',
            component: () => import('../views/index/project-detail/chart-manage/chart-list/index.vue'),
            meta: {
              sidebar: 'project'
            }
          },
          {
            path: 'data-source',
            component: () => import('../views/index/project-detail/data-manage/data-source.vue'),
            meta: {
              sidebar: 'project'
            }
          },
          {
            path: 'sql-editor',
            component: () => import('../views/index/project-detail/sql-manage/sql-editor.vue'),
            meta: {
              sidebar: 'project'
            }
          },
          {
            path: 'sql-query',
            component: () => import('../views/index/project-detail/sql-manage/sql-query.vue'),
            meta: {
              sidebar: 'project'
            }
          }
        ]
      },
      {
        path: 'chart-detail',
        component: () => import('../views/index/chart-detail'),
        meta: {
          sidebar: 'project'
        }
      },
      {
        path: 'user-manage',
        component: () => import('../views/index/user-manage'),
        meta: {
          requireAuth: true,
          sidebar: 'user'
        }
      },
      {
        path: 'data-form',
        component: () => import('../views/index/data-form'),
        meta: {
          sidebar: 'form'
        }
      }
    ]
  },
  {
    // 登录
    path: '/login',
    component: () => import('../views/login')
  },
  {
    path: '*',
    redirect: '/'
  }
]

const router = new Router({
  mode: 'history',
  routes,
  linkActiveClass: 'link-active',
  scrollBehavior (to, from, savedPosition) {
    return savedPosition || { x: 0, y: 0 }
  }
})

router.beforeEach(async (to, from, next) => {
  bar.start()
  if (to.matched.some(record => record.meta.requireAuth)) {
    const token = cookie.get('token')
    if (token) {
      next()
    } else {
      next('/login')
    }
  }
  next()
})

router.afterEach((to, from) => {
  bar.finish()
})

export default router
