<template>
  <div id="app">
    <router-view v-if="isRouterAlive"></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  provide () {
    return {
      $_reload: this.$_reload
    }
  },
  data () {
    return {
      isRouterAlive: true
    }
  },
  methods: {
    $_reload () {
      this.isRouterAlive = false
      this.$nextTick(function () {
        this.isRouterAlive = true
      })
    }
  }
}
</script>

<style lang="scss">
 #app {
   height: 100%;
   width: 100%;
   overflow: auto;
 }
@import './style/index.scss';
@import url('//at.alicdn.com/t/font_1687133_yh36ks5ktnl.css');
</style>
