<template>
  <el-dialog
    title="修改密码"
    width="600px"
    :visible.sync="$_visible"
    @close="closeDialog">
    <p slot="title" class="text_size_16 text_color_white text_weight_bold">修改密码</p>
    <el-form
      class="form padding-left-20 padding-right-20"
      ref="form"
      :model="form"
      :rules="rules"
      size="small"
      label-position="left"
      label-width="160px"
      hide-required-asterisk>
      <el-form-item label="旧密码" prop="oldPassword">
        <el-input type="password" v-model="form.oldPassword" placeholder="请输入旧密码"></el-input>
      </el-form-item>
      <el-form-item label="新密码" prop="newPassword">
        <el-input type="password" v-model="form.newPassword" placeholder="请输入新密码"></el-input>
      </el-form-item>
      <el-form-item label="确认密码" prop="confirm">
        <el-input type="password" v-model="form.confirm" placeholder="请确认密码"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button size="small" @click="closeDialog">取消</el-button>
      <el-button type="primary" :loading="saveBtnLoading" size="small" @click="saveForm">确定</el-button>
    </div>
  </el-dialog>
</template>
<script>
import SHA256 from 'crypto-js/sha256'
import cookie from '~/config/cookie'
import * as api from '~/api'
export default {
  data () {
    return {
      // 确定按钮loading
      saveBtnLoading: false,
      // 表单对象
      form: {
        oldPassword: '',
        newPassword: '',
        confirm: ''
      },
      // 添加目标表达那对象验证
      rules: {
        oldPassword: [
          { required: true, message: '请输入旧密码', trigger: 'blur' },
          { pattern: /^(?=.*[a-zA-Z])(?=.*\d)[A-Za-z\d]{8,16}/, message: '密码长度为8~16位，至少需要包含数字、字母两种类型', trigger: 'blur' }
        ],
        newPassword: [
          { required: true, message: '请输入新密码', trigger: 'blur' },
          { pattern: /^(?=.*[a-zA-Z])(?=.*\d)[A-Za-z\d]{8,16}/, message: '密码长度为8~16位，至少需要包含数字、字母两种类型', trigger: 'blur' }
        ],
        confirm: [
          { required: true, message: '请确认密码', trigger: 'blur' },
          { pattern: /^(?=.*[a-zA-Z])(?=.*\d)[A-Za-z\d]{8,16}/, message: '密码长度为8~16位，至少需要包含数字、字母两种类型', trigger: 'blur' }
        ]
      }
    }
  },
  props: {
    // 父组件传来的控制
    visible: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    $_visible: {
      get () {
        return this.visible
      },
      set (newValue) {
        this.$emit('update:visible', newValue)
      }
    }
  },
  methods: {
    // 修改密码
    resetUserPw (result) {
      this.saveBtnLoading = true
      api.resetUserPw({
        oldPassword: SHA256(this.form.oldPassword).toString(),
        newPassword: SHA256(this.form.newPassword).toString()
      }).then(res => {
        if (res.data.code === 0) {
          // console.log('修改密码成功', res.data.data)
          this.$message.success('修改密码成功')
          this.closeDialog()

          cookie.remove('token')
          this.$router.push('/login')
        } else {
          this.$message.error(res.data.message)
        }
        this.saveBtnLoading = false
      }).catch(err => {
        this.saveBtnLoading = false
        console.log(err)
        this.$message.error('网络错误，请重试！')
      })
    },

    // 保存提交
    saveForm () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.form.newPassword === this.form.confirm) {
            this.resetUserPw()
          } else {
            this.$message.warning('两次输入的密码不一致')
            return false
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 关闭添加表单的dialog
    closeDialog () {
      this.$refs.form.resetFields()
      this.$_visible = false
    }
  }
}
</script>
<style lang="scss" scoped>

</style>
