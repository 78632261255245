<template>
  <div class="layout_sidebar">
    <!-- 项目管理 -->
    <router-link
      tag="div"
      to="/project-manage"
      class="layout_sidebar_item project"
      :class="sidebar === 'project' ? 'select' : ''">
      <i class="iconfont iconxiangmu"></i>
      <p class="text_size_12">项目管理</p>
      <div class="layout_sidebar_subs"
        v-loading="sidebarLoading"
        element-loading-background="rgba(24,34,45,0.9)">
        <vue-scroll>
          <div class="layout_sidebar_subs-title">
            <span></span>
            项目
          </div>
          <router-link
            class="layout_sidebar_subs-item"
            tag="div"
            :to="{
              path: '/project-detail',
              query: {
                projectName: encodeURIComponent(item.title),
                projectId: item.id,
                projectType: 'project'
              }
            }"
            v-for="(item) in projectList"
            :key="item.id">
            <p :title="item.title ">{{ item.title }}</p>
          </router-link>

          <div class="layout_sidebar_subs-title">
            <span></span>
            空间
          </div>
          <router-link
            class="layout_sidebar_subs-item"
            tag="div"
            :to="{
              path: '/project-detail',
              query: {
                projectName: encodeURIComponent(item.title),
                projectId: item.id,
                projectType: 'space'
              }
            }"
            v-for="(item) in spaceList"
            :key="item.id">
            <p :title="item.title ">{{ item.title }}</p>
          </router-link>
        </vue-scroll>
      </div>
    </router-link>
    <!-- 用户管理 -->
    <router-link
      v-if="userRole === 2"
      tag="div"
      to="/user-manage"
      class="layout_sidebar_item"
      :class="sidebar === 'user' ? 'select' : ''">
      <i class="iconfont iconren"></i>
      <p class="text_size_12">用户管理</p>
    </router-link>
    <div class="layout_sidebar_divider"></div>
    <!-- 展识 -->
    <div class="layout_sidebar_item" @click="toZhanSHi">
      <i class="iconfont icontubiao text_size_36"></i>
    </div>

    <!-- 侧边栏展出[项目及个人空间列表] -->
    <!-- <transition name="el-fade-in-linear">
      <div
        class="layout_sidebar_subs"
        v-if="isOnProject"
        @mouseenter="isOnProject = true"
        @mouseleave="isOnProject = false"
        v-loading="sidebarLoading"
        element-loading-background="rgba(24,34,45,0.9)">
        <vue-scroll>
          <div class="layout_sidebar_subs-title">
            <span></span>
            项目
          </div>
          <router-link
            class="layout_sidebar_subs-item"
            tag="div"
            :to="{
              path: '/project-detail',
              query: {
                projectName: encodeURIComponent(item.title),
                projectId: item.id,
                projectType: 'project'
              }
            }"
            v-for="(item) in projectList"
            :key="item.id">
            <p :title="item.title ">{{ item.title }}</p>
          </router-link>

          <div class="layout_sidebar_subs-title">
            <span></span>
            空间
          </div>
          <router-link
            class="layout_sidebar_subs-item"
            tag="div"
            :to="{
              path: '/project-detail',
              query: {
                projectName: encodeURIComponent(item.title),
                projectId: item.id,
                projectType: 'space'
              }
            }"
            v-for="(item) in spaceList"
            :key="item.id">
            <p :title="item.title ">{{ item.title }}</p>
          </router-link>
        </vue-scroll>
      </div>
    </transition> -->
  </div>
</template>

<script>
import * as api from '~/api'
import { IDEA_SPREAD_URL } from '~/config/index'

export default {
  data () {
    return {
      sidebarLoading: false,
      isOnProject: false,
      // 项目列表
      projectList: [],
      // 空间列表
      spaceList: []
    }
  },
  computed: {
    // 当前激活项
    sidebar () {
      return this.$route.meta.sidebar
    },
    // 用户角色
    userRole () {
      return this.$store.state.userInfo.userType
    }
  },
  created () {
    // 获取项目及空间
    this.getProjectSpaceList()
  },
  methods: {
    // 获取项目及个人空间列表
    getProjectSpaceList (result) {
      this.sidebarLoading = true
      api.getProjectSpaceList().then(res => {
        if (res.data.code === 0) {
          console.log('项目及空间列表', res.data.data)
          this.projectList = res.data.data.project_spaces
          this.spaceList = res.data.data.private_spaces
        } else {
          this.$message.error(res.data.message)
        }
        this.sidebarLoading = false
      }).catch(err => {
        this.sidebarLoading = false
        console.log(err)
        this.$message.error('网络错误，请重试！')
      })
    },
    toZhanSHi () {
      const url = IDEA_SPREAD_URL
      window.open(url)
    }
  }
}
</script>

<style lang="scss" scoped>
.layout_sidebar {
  position: relative;
  width: 60px;
  background-color: $black-color-light-5;
  padding-top: 10px;
  &_item {
    width: 60px;
    height: 60px;
    margin-top: 10px;
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    color: $color-text-secondary;
    &:hover {
      cursor: pointer;
      background-color: #3F4954;
      color: $color-white;
    }
    &.select {
      background-color: #242E3A;
      color: $color-white;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0%;
        width: 2px;
        height: 60px;
        border-left: 2px solid #6AEBFF;
      }
    }
    &.project {
      &:hover {
        .layout_sidebar_subs {
          display: block;
        }
      }
    }
  }
  &_divider {
    margin: 10px 10px 0 10px;
    border-top: 1px solid #89939E;
  }
  &_subs {
    display: none;
    position: fixed;
    top: 51px;
    left: 60px;
    bottom: 0;
    z-index: 9999;
    width: 200px;
    background-color: $black-color-light-2;
    &-title {
      height: 40px;
      color: $black-color-light-10;
      padding: 0 10px;
      display: flex;
      align-items: center;
      & > span {
        width: 6px;
        height: 6px;
        margin-right: 10px;
        background: $color-primary;
        border-radius: 50%;
      }
    }
    &-item {
      padding: 5px 10px;
      color: $color-white;
      cursor: pointer;
      & > p {
        width: 180px;
        height: 36px;
        padding: 0 10px;
        line-height: 36px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        &:hover {
          border-radius: 2px;
          background-color: $black-color-light-3;
        }
      }
    }
  }
}
</style>
