// 全局的过滤器
import Vue from 'vue'
import moment from 'moment'

Vue.filter('dataFormat', (input, pattern = 'YYYY-MM-DD') => {
  return moment(input).format(pattern)
})

// 格式化用户类型
Vue.filter('formatUserType', value => {
  return value === 1 ? '操作员' : value === 2 ? '管理员' : ''
})

// 格式化用户的激活状态
Vue.filter('formatUserStatus', value => {
  return value === 1 ? '已激活' : value === 0 ? '未激活' : ''
})
