import { post, get, put, remove } from '~/config/api'
import { API_BASE_URL } from '~/config/index'

export const isRefresh = true
export const uploadURL = API_BASE_URL + '/api/v1/portal/common/uploads'

/**
 * 用户登录验证
 * @param {*} data
 */
export const login = (data) => post('/api/v1/login', data)

/**
 * 获取用户个人信息
 */
export const getUserInfo = () => get('/api/v1/user/info')

/**
 * 修改用户信息
 * @param {*} data
 */
export const updateUserInfo = (data) => put('/api/v1/user/info/edit', data)

/**
 * 修改用户个人密码
 * @param {*} data
 */
export const resetUserPw = (data) => put('/api/v1/user/new_password', data)

/**
 * 获取项目和个人空间列表
 */
export const getProjectSpaceList = () => get('/api/v1/spaces')

/**
 * 获取指定项目下的图表分组
 * @param {*} data
 */
export const getChartGroupList = (data) => get('/api/v1/space/chart/groups', data)

/**
 * 获取指定项目下的图表类型分组列表
 * @param {*} data
 */
export const getTypeGroupList = (data) => get('/api/v1/space/chart/type/groups', data)

/**
 * 新建图表分组
 * @param {*} data
 */
export const createChartGroup = (data) => post('/api/v1/space/chart/groups/add', data)

/**
 * 删除分组
 * @param {*} data
 */
export const deleteChartGroup = (data) => put('/api/v1/space/chart/groups/del', data)

/**
 * 修改图表分组信息
 *  + 重命名
 * @param {*} data
 */
export const editChartGroup = (data) => put('/api/v1/space/chart/groups/edit', data)

/**
 * 获取分组图表列表的数据
 * @param {*} data
 */
export const getChartList = (data) => get('/api/v1/space/group/chart/list', data)

/**
 * 重命名图表名称
 * @param {*} data
 */
export const editChartName = (data) => put('/api/v1/space/group/chart/rename', data)

/**
 * 删除图表
 * @param {*} data
 */
export const deleteChart = (data) => put('/api/v1/space/group/chart/list/del', data)

/**
 * 移动图表
 * @param {*} data
 */
export const moveChart = (data) => put('/api/v1/space/group/chart/move', data)

/**
 * 复制图表
 * @param {*} data
 */
export const copyChart = (data) => post('/api/v1/space/group/chart/copy', data)

/**
 * 获取操作员列表
 * @param {*} data
 */
export const getUserList = (data) => get('/api/v1/operators/list', data)

/**
 * 新增操作员
 * @param {*} data
 */
export const createUser = (data) => post('/api/v1/operators/add', data)

/**
 * 删除操作员
 * @param {*} data
 */
export const removeUser = (data) => put('/api/v1/operators/del', data)

/**
 * 修改操作员信息
 * @param {*} data
 */
export const updateUser = (data) => put('/api/v1/operators/edit', data)

/**
 * 修改操作员密码
 * @param {*} data
 */
export const updateUserPassword = (data) => put('/api/v1/operators/new_password', data)

/**
 * 获取项目下所有数据源
 * @param {*} data
 */
export const getDataSourceList = (data) => get('/api/v1/space/datasources/list', data)

/**
 * 获取数据源相关sql
 * @param {*} data
 */
export const getDataSourceSql = (data) => get('/api/v1/space/datasources/sql/list', data)

/**
 * 新增数据源
 * @param {*} data
 */
export const createDataSource = (data) => post('/api/v1/space/datasources/add', data)

/**
 * 新增 CSV 类型的数据源
 * @param {*} data
 */
export const createDataSourceCSV = (data) => post('/api/v1/space/datasources/add/csv', data)

/**
 * 编辑数据源
 * @param {*} data
 */
export const editDataSource = (data) => put('/api/v1/space/datasources/edit', data)

/**
 * 删除数据源
 * @param {*} data
 */
export const deleteDataSource = (data) => put('/api/v1/space/datasources/del', data)

/**
 * 获取数据库的数据表
 * 传入数据源id
 * @param {*} data
 */
export const getDatabaseTableList = (data) => get('/api/v1/space/datasource/database/tables', data)

/**
 * 获取数据源的预览数据
 * @param {*} data
 */
export const getPreviewData = (data) => get('/api/v1/space/datasource/data', data)

/**
 * 获取SQL执行结果
 * @param {*} data
 */
export const getExecuteSqlResult = (data) => post('/api/v1/space/sqllab/execute_sql', data)

/**
 * 保存查询 保存sql语句
 * @param {*} data
 */
export const saveQuery = (data) => post('/api/v1/sapce/slqlab/create', data)

/**
 * 获取已保存的sql查询列表
 * @param {*} data
 */
export const getSavedQueryList = (data) => get('/api/v1/space/sqllab/saved/search', data)

/**
 * 获取已保存的sql查询详情
 * @param {*} data
 */
export const getSavedQueryDetail = (data) => get('/api/v1/space/sqllab/sql', data)

/**
 * 编辑已保存的sql查询
 * @param {*} data
 */
export const editSavedQueryItem = (data) => put('/api/v1/space/sqllab/saved/edit', data)

/**
 * 删除一项sql查询
 * @param {*} data
 */
export const deleteSavedQueryItem = (data) => put('/api/v1/space/sqllab/saved/del', data)

/**
 * 运行图表，获取数据
 * 获取图表预览数据
 * @param {*} data
 */
export const runChart = (data) => post('/api/v1/space/group/chart/explore', data)

/**
 * 保存图表相关改动
 * @param {*} data
 */
export const saveChart = (data) => post('/api/v1/space/group/chart/save', data)

/**
 * 另存为
 * @param {*} data
 */
export const saveAsChart = (data) => post('/api/v1/space/group/chart/save_as', data)

/**
 * 创建图表
 * @param {*} data
 */
export const createChart = (data) => post('/api/v1/space/group/chart/create', data)

/**
 * 获取图表详情
 * @param {*} data
 */
export const getChartDetail = (data) => get('/api/v1/space/group/chart/info', data)

/**
 * 分享的图表获取需要的数据
 * @param {*} data
 */
export const getSharedChartInfo = (data) => get('/api/v1/space/group/chart/share', data)

/**
 * 获取推荐的图标类型
 * @param {*} data
 */
export const getChartSuggestion = (data) => post('/api/v1/space/group/chart/recommend', data)

/**
 * 创建度量预警
 * @param {*} data
 */
export const createWarning = (data) => post('/api/v1/space/group/chart/warning/add', data)

/**
 * 获取度量预警列表
 * @param {*} data
 */
export const getWarningList = (data) => get('/api/v1/space/group/chart/warning/list', data)

/**
 * 获取度量预警详情
 * @param {*} data
 */
export const getWarningDetail = (data) => get('/api/v1/space/group/chart/warning/detail', data)

/**
 * 获取度量预警可用的字段
 * @param {*} data
 */
export const getWarningColumns = (data) => get('/api/v1/space/group/chart/warning/situation/col', data)

/**
 * 编辑度量预警
 * @param {*} data
 */
export const editWarning = (data) => put('/api/v1/space/group/chart/warning/edit', data)

/**
 * 删除度量预警
 * @param {*} data
 */
export const deleteWarning = (data) => remove('/api/v1/space/group/chart/warning/del', data)
