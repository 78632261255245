import Vue from 'vue'

/**
 * 全局自定义指令 v-clicl-outside 点击外部
 * 使用方法：v-clicl-outside="fn"
 */
Vue.directive('clickOutside', {
  // 点击外部时
  bind (el, binding) {
    el.__vueClickOutside__ = function documentHandle (e) {
      if (el.contains(e.target)) {
        return false
      }
      if (binding.expression) {
        binding.value(e)
      }
    }
    document.addEventListener('click', el.__vueClickOutside__, true)
  },
  unbind (el) {
    document.removeEventListener('click', el.__vueClickOutside__, true)
    delete el.__vueClickOutside__
  }
})
