import * as api from '~/api'

export default {
  async getUserInfo ({ commit }) {
    let res
    try {
      res = await api.getUserInfo()
    } catch (err) {
      return
    }
    if (res.data.code === 0) {
      commit('SET_USER_INFO', res.data.data)
    }
  }
}
