// 接口请求地址
export const API_BASE_URL = process.env.VUE_APP_API_BASE_URL

// cookie域
export const COOKIE_DOMAIN = process.env.VUE_APP_COOKIE_DOMAIN

// 获取bi图片的接口
export const SUPERSET_URL = process.env.VUE_APP_SUPERSET_URL

// 展识bi-web前端地址
export const IDEA_SPREAD_URL = process.env.VUE_APP_IDEA_SPREAD_URL
