import Vue from 'vue'
import clone from 'clone'
import './element-ui'
import App from './App'
import router from './router'
import store from './store'
import './vuescroll'

// 全局组件
import './utils/components'
// 全局的过滤器
import './utils/filter'
// 全局指令
import './utils/directive'

// deep clone
Vue.prototype.$clone = clone

Vue.config.productionTip = false

// 存储初始状态的state，退出登录后用于重置state
sessionStorage.setItem('initState', JSON.stringify(store.state))

/* eslint-disable no-new */
new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
