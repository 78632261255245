import Vue from 'vue'
import Vuex from 'vuex'
import actions from './actions'
import mutations from './mutations'
// 引入store模块
// import outer from './modules/outer'

Vue.use(Vuex)

const state = {
  // 用户的基本信息
  userInfo: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : {
    id: '',
    // 用户名称
    userName: '',
    // 用户手机号
    userPhone: '',
    // 用户邮箱
    userEmail: '',
    // 用户身份：2-管理员；1-操作员
    userType: -1,
    // 是否激活: 0：未激活  1：已经激活
    isActivate: -1
  }
}

const options = {
  state,
  mutations,
  actions,
  modules: {
  }
}

export default new Vuex.Store(options)
