<template>
  <div :class="['text_label_value text_size_14', {'align_center': xText.alignCenter}]" :style="textItemStyle">
    <span class="label" :style="labelStyle">
      {{ label }}
    </span>
    <span class="value" :style="valueStyle">
      <slot></slot>
    </span>
  </div>
</template>
<script>
export default {
  inject: ['xText'],
  props: {
    label: {
      type: String,
      default: ''
    }
  },
  computed: {
    textItemStyle () {
      return {
        minHeight: `${this.xText.height}px`,
        marginBottom: `${this.xText.marginBottom}px`
      }
    },
    labelStyle () {
      return {
        width: `${this.xText.labelWidth}px`,
        height: `${this.xText.height}px`,
        lineHeight: `${this.xText.height}px`
      }
    },
    valueStyle () {
      return {
        lineHeight: `${this.xText.height}px`
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  .text_label_value {
    display: flex;
    width: 100%;
    min-height: 32px;
    .label {
      width: 120px;
      height: 32px;
      line-height: 32px;
      color: $color-text-regular;
    }
    .value {
      flex: 1;
      line-height: 32px;
      color: white;
      & > .textarea {
        line-height: 28px;
      }
    }
  }
</style>
