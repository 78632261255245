/*
  *******************
      注册全局组件
  *******************
*/
import Vue from 'vue'

import layout from '~/components/layout'

import sectionTitle from '~/components/section-title'

import xText from '~/components/x-text/index'
import xTextItem from '~/components/x-text/x-text-item'

Vue.component('layout', layout)

Vue.component('sectionTitle', sectionTitle)

Vue.component('xText', xText)
Vue.component('xTextItem', xTextItem)
