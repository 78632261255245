var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"layout_sidebar"},[_c('router-link',{staticClass:"layout_sidebar_item project",class:_vm.sidebar === 'project' ? 'select' : '',attrs:{"tag":"div","to":"/project-manage"}},[_c('i',{staticClass:"iconfont iconxiangmu"}),_c('p',{staticClass:"text_size_12"},[_vm._v("项目管理")]),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.sidebarLoading),expression:"sidebarLoading"}],staticClass:"layout_sidebar_subs",attrs:{"element-loading-background":"rgba(24,34,45,0.9)"}},[_c('vue-scroll',[_c('div',{staticClass:"layout_sidebar_subs-title"},[_c('span'),_vm._v(" 项目 ")]),_vm._l((_vm.projectList),function(item){return _c('router-link',{key:item.id,staticClass:"layout_sidebar_subs-item",attrs:{"tag":"div","to":{
            path: '/project-detail',
            query: {
              projectName: encodeURIComponent(item.title),
              projectId: item.id,
              projectType: 'project'
            }
          }}},[_c('p',{attrs:{"title":item.title}},[_vm._v(_vm._s(item.title))])])}),_c('div',{staticClass:"layout_sidebar_subs-title"},[_c('span'),_vm._v(" 空间 ")]),_vm._l((_vm.spaceList),function(item){return _c('router-link',{key:item.id,staticClass:"layout_sidebar_subs-item",attrs:{"tag":"div","to":{
            path: '/project-detail',
            query: {
              projectName: encodeURIComponent(item.title),
              projectId: item.id,
              projectType: 'space'
            }
          }}},[_c('p',{attrs:{"title":item.title}},[_vm._v(_vm._s(item.title))])])})],2)],1)]),(_vm.userRole === 2)?_c('router-link',{staticClass:"layout_sidebar_item",class:_vm.sidebar === 'user' ? 'select' : '',attrs:{"tag":"div","to":"/user-manage"}},[_c('i',{staticClass:"iconfont iconren"}),_c('p',{staticClass:"text_size_12"},[_vm._v("用户管理")])]):_vm._e(),_c('div',{staticClass:"layout_sidebar_divider"}),_c('div',{staticClass:"layout_sidebar_item",on:{"click":_vm.toZhanSHi}},[_c('i',{staticClass:"iconfont icontubiao text_size_36"})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }