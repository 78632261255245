import Vue from 'vue'
// 只导入自定义滚动条组件
import vuescroll from 'vuescroll/dist/vuescroll-native'
// 导入css文件
import 'vuescroll/dist/vuescroll.css'

// 你可以在这里设置全局配置
/**
 * Vuescroll 的配置项由 5 部分组成,
    vuescroll
    scrollPanel       内容的包装. 我们通过改变 scrollPanel 的 scrollLeft 和 scrollTop 来使内容移动
    bar               滑 块
    rail             轨道
    scrollButton.    上下箭头
 */
// 在这里设置全局默认配置
Vue.use(vuescroll, {
  ops: {
    rail: {
      // 轨道的透明度
      opacity: 0,
      // 轨道的边框
      border: 0
    },
    bar: {
      // 滚动条的背景颜色
      background: '#4B5560',
      onlyShowBarOnScroll: false
    }
  }
  // name: 'myScroll' // 在这里自定义组件名字，默认是vueScroll
})
