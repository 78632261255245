<template>
  <div class="x_text">
    <slot></slot>
  </div>
</template>
<script>
export default {
  provide () {
    return {
      xText: this
    }
  },
  props: {
    labelWidth: {
      type: Number,
      default: 120
    },
    height: {
      type: Number,
      default: 32
    },
    alignCenter: {
      type: Boolean,
      default: false
    },
    marginBottom: {
      type: Number,
      default: 18
    }
  }
}
</script>
<style lang="scss" scoped>

</style>
