<template>
  <div class="layout_main">
    <slot></slot>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss">
@keyframes bgToggle {
  0% {
    background-image: url('../../../assets/img/toggle_bg_1.png');
    background-size: cover;
  }
  50% {
    background-image: url('../../../assets/img/toggle_bg_2.png');
    background-size: cover;
  }
  100% {
    background-image: url('../../../assets/img/toggle_bg_1.png');
    background-size: cover;
  }
}
</style>

<style lang="scss" scoped>
.layout_main {
  flex: 1;
  animation: bgToggle 10s infinite;
  background-color: #030d17;
  overflow: auto;
}
</style>
