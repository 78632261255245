<template>
  <el-dialog
    title="修改密码"
    width="600px"
    :visible.sync="$_visible"
    @open="handleDialogOpen"
    @close="closeDialog">
    <p slot="title" class="text_size_16 text_color_white text_weight_bold">修改密码</p>
    <el-form
      class="form padding-left-20 padding-right-20"
      ref="form"
      :model="form"
      :rules="rules"
      size="small"
      label-position="left"
      label-width="160px"
      hide-required-asterisk>
      <el-form-item label="姓名" prop="userName">
        <el-input type="text" v-model="form.userName" placeholder="请输入姓名"></el-input>
      </el-form-item>
      <el-form-item label="手机号">
        {{ form.userPhone }}
      </el-form-item>
      <el-form-item label="角色权限">
        <span class="text_color_white">{{ form.userType | formatUserType }}</span>
      </el-form-item>
      <el-form-item label="电子邮箱">
        <el-input type="email" v-model="form.userEmail" placeholder="请输入邮箱"></el-input>
      </el-form-item>
      <el-form-item label="是否激活">
        <span class="text_color_active">{{ form.isActivate | formatUserStatus }}</span>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button size="small" @click="closeDialog">取消</el-button>
      <el-button type="primary" :loading="saveBtnLoading" size="small" @click="saveForm">确定</el-button>
    </div>
  </el-dialog>
</template>
<script>
import * as api from '~/api'
export default {
  data () {
    return {
      // 确定按钮loading
      saveBtnLoading: false,
      // 表单对象
      form: {
        id: '',
        // 用户名称
        userName: '',
        // 用户手机号
        userPhone: '',
        // 用户邮箱
        userEmail: '',
        // 用户身份：2-管理员；1-操作员
        userType: -1,
        // 是否激活: 0：未激活  1：已经激活
        isActivate: -1
      },
      // 添加目标表达那对象验证
      rules: {
        userName: [
          { required: true, message: '请输入姓名', trigger: 'blur' }
        ],
        userEmail: [
          { required: true, message: '请输入邮箱', trigger: 'blur' },
          { pattern: /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/, message: '邮箱格式错误', trigger: 'blur' }
        ]
      }
    }
  },
  props: {
    // 父组件传来的控制
    visible: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    $_visible: {
      get () {
        return this.visible
      },
      set (newValue) {
        this.$emit('update:visible', newValue)
      }
    },
    // 用户个人信息
    userInfo () {
      return this.$store.state.userInfo
    }
  },
  methods: {
    // 更新个人信息
    updateUserInfo (result) {
      this.saveBtnLoading = true
      api.updateUserInfo({
        userName: this.form.userName,
        userEmail: this.form.userEmail
      }).then(res => {
        if (res.data.code === 0) {
          // console.log('修改用户信息成功', res.data.data)
          this.$message.success('更新信息成功！')

          // 重新获取
          this.$store.dispatch('getUserInfo')
          this.closeDialog()
        } else {
          this.$message.error(res.data.message)
        }
        this.saveBtnLoading = false
      }).catch(err => {
        this.saveBtnLoading = false
        console.log(err)
        this.$message.error('网络错误，请重试！')
      })
    },

    // 保存提交
    saveForm () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          // 更新用户信息
          this.updateUserInfo()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 关闭添加表单的dialog
    closeDialog () {
      this.$refs.form.resetFields()
      this.$_visible = false
    },
    // dialog打开之前
    handleDialogOpen () {
      this.form = this.$clone(this.userInfo)
    }
  }
}
</script>
<style lang="scss" scoped>

</style>
