<template>
  <div class="layout_header">
    <img class="header_title" src="../../../assets/img/header/header_title.png" width="145" height="24" alt="大数据驾驶舱">
    <avatar></avatar>
  </div>
</template>
<script>
import avatar from './avatar/index'
export default {
  components: {
    avatar
  }
}
</script>
<style lang="scss" scoped>
  .layout_header {
    flex: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 51px;
    border-bottom: 1px solid $color-primary;
    background-color: $black-color-light-1;
    .header_title {
      margin-left: 23px;
    }
  }
</style>
