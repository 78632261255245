<template>
  <div class="layout">
    <layout-header></layout-header>
    <div class="layout_body">
      <layout-sidebar></layout-sidebar>
      <layout-main>
        <slot name="layout-main"></slot>
      </layout-main>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import layoutHeader from './header/index'
import layoutSidebar from './sidebar'
import layoutMain from './main'
export default {
  components: {
    layoutHeader,
    layoutSidebar,
    layoutMain
  }
}
</script>

<style lang="scss" scoped>
.layout {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  &_body {
    flex: 1;
    display: flex;
    overflow: auto;
  }
}
</style>
